<template>
  
              <v-list-item @click="logout" prepend-icon="mdi-logout" title="Odhlásit se" value="logout"></v-list-item>

  </template>
  
  <script>
  
  export default {
    data() {
      return {
        userData: null, // Objekt pro uchování údajů o uživateli
      };
    },
    mounted() {
      // Check if there is data in localStorage and set it to userData
      const storedEmail = localStorage.getItem('email');
      const storedGoogleId = localStorage.getItem('google_id');
      const storedRoleId = localStorage.getItem('role_id');

      if (storedEmail && storedGoogleId) {
        this.userData = {
          email: storedEmail,
          google_id: storedGoogleId,
          role_id: storedRoleId
        };
      }
    },
    methods: {
      async logout() {
        // Odstranění dat o uživateli z localStorage
        localStorage.removeItem('email');
        localStorage.removeItem('google_id');
          localStorage.removeItem('role_id');

        // Nulování userData
        this.userData = null;
        this.$router.push({ path: '/prihlaseni', query: { logoutSuccess: true } });

      },
    },
  };
  </script>
  
  
  