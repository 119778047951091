import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import axios from 'axios';

const firebaseConfig = {
  apiKey: "AIzaSyAj7pZ9X9MKvam_tki6EPIDtef7nK_YK2M",
  authDomain: "todolocal-5f07f.firebaseapp.com",
  projectId: "todolocal-5f07f",
  storageBucket: "todolocal-5f07f.appspot.com",
  messagingSenderId: "388395540452",
  appId: "1:388395540452:web:ab800f58d92f0825b0f1f1"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const initializeFirebaseMessaging = async () => {
  try {
    // Zkontrolujte, zda je povoleno, že web může zobrazovat notifikace
    if (Notification.permission === 'granted') {
      const vapidKey = 'BJNLbcOqLlqWNblf4wZcU3-Glp9izKQz4JnJCi07-sqN7yqBKa90tAXCrYf4juhS2-ynmXiWAqpAyGDkeK8wBZM';
      const token = await getToken(messaging, { vapidKey });

      if (token) {
        console.log('FCM Token:', token);

        // Získat google_id z localStorage
        const googleId = localStorage.getItem('google_id');

        if (googleId) {
          // Odeslat token a google_id na server
          await axios.post('https://betatodo.oznamzde.cz/api/v2/save-fcm-token', { token, google_id: googleId });
        } else {
          console.error('Google ID not found in localStorage.');
        }

      } else {
        console.log('No registration token available.');
      }

    } else if (Notification.permission === 'default') {
      // Požádejte uživatele o povolení notifikací
      const permission = await Notification.requestPermission();

      if (permission === 'granted') {
        // Pokud uživatel povolí notifikace, obnovte stránku
        window.location.reload();
      } else {
        console.error('Notification permission not granted.');
      }

    } else {
      console.error('Notification permission denied.');
    }

    // Zpracování příchozích zpráv v popředí
    onMessage(messaging, (payload) => {
      console.log('Message received in foreground:', payload);
      new Notification(payload.notification.title, {
        body: payload.notification.body,
        icon: payload.notification.icon
      });
    });

    return messaging;

  } catch (error) {
    console.error('Error initializing Firebase Messaging:', error);
  }
};
