<template>
  <v-layout>
    <v-app-bar app :class="{'dark-mode': isDarkModeLocal, 'light-mode': !isDarkModeLocal}">
      <v-btn class="ml-4" style="font-size: 24px;" icon @click="toggleDrawer">
        <v-icon>{{ 'mdi-menu' }}</v-icon>
      </v-btn>

  <v-btn @click="toggleDarkMode" >
        Přepnout pozadí</v-btn>  
        
                            <v-btn @click="logout" prepend-icon="mdi-logout" title="Odhlásit se" value="logout">Odhlásit se</v-btn>

        
          </v-app-bar>

    <!-- Podmínkové vykreslování navigačního draweru -->
    <v-navigation-drawer
      v-if="showNavigationDrawer"
      rail
      expand-on-hover
      v-model="menu"
      app
      :class="{'dark-mode': isDarkModeLocal, 'light-mode': !isDarkModeLocal}"
    >
      <v-divider></v-divider>
      <v-list nav>
        <router-link to="/admin/todo" exact>
          <v-list-item :class="{ 'highlighted': $route.path === '/admin/todo' }" prepend-icon="mdi-calendar-check" title="Úkolníček" value="todo"></v-list-item>
        </router-link>
        <router-link to="/settings/users" exact>
          <v-list-item :class="{ 'highlighted': $route.path === '/settings/users' }" prepend-icon="mdi-account-multiple" title="Uživatelé a role" value="usersandrole"></v-list-item>
        </router-link>
        <Logout />
      </v-list>
    </v-navigation-drawer>
  </v-layout>
</template>

<script>
import Logout from "../Auth/LogoutSidebar.vue";
import { ref, onMounted, watch, computed } from 'vue';

export default {

     methods: {
      async logout() {
        // Odstranění dat o uživateli z localStorage
        localStorage.removeItem('email');
        localStorage.removeItem('google_id');
          localStorage.removeItem('role_id');

        // Nulování userData
        this.userData = null;
        this.$router.push({ path: '/prihlaseni', query: { logoutSuccess: true } });

      },
    },
    
  props: {
    isDarkMode: {
      type: Boolean,
      default: false
    }
  },
    setup(props, { emit }) {
    const menu = ref(true);
    const isDarkModeLocal = ref(props.isDarkMode);
    const showNavigationDrawer = ref(true);
    const currentModeClass = computed(() => {
      return isDarkModeLocal.value ? 'dark-mode' : 'light-mode';
    });

    watch(() => props.isDarkMode, (newVal) => {
      isDarkModeLocal.value = newVal;
    });

  
    const toggleDrawer = () => {
      menu.value = !menu.value;
    };

    const toggleDarkMode = () => {
      isDarkModeLocal.value = !isDarkModeLocal.value;
      
      emit('toggle-dark-mode');
    };


    // Načtení role_id při mountování komponentu
    onMounted(() => {
      const roleId = localStorage.getItem('role_id');
      if (roleId === '2') {
        showNavigationDrawer.value = false;
      }
    });

    return {
       menu,
      isDarkModeLocal,
      showNavigationDrawer,
      toggleDrawer,
      toggleDarkMode,
      currentModeClass
    };
  },
  components: {
    Logout
  }
};
</script>

<style scoped>
/* (Stylování zůstává beze změny) */
</style>

<style scoped>
a {
  text-decoration: none;
  color: inherit;
}

.highlighted {
  background-color: #b6e5fb;
  color: #000;
}

.dark-mode {
  background-color: #333; /* Barva pozadí pro tmavý režim */
  color: #fff; /* Barva textu pro tmavý režim */
}

.light-mode {
  background-color: #fff; /* Barva pozadí pro světelný režim */
  color: #000; /* Barva textu pro světelný režim */
}

/* Specifické styly pro v-navigation-drawer */
.v-navigation-drawer.dark-mode {
  background-color: #2c2c2c; /* Barva pozadí pro tmavý režim */
  color: #fff; /* Barva textu pro tmavý režim */
}

.v-navigation-drawer.light-mode {
  background-color: #f5f5f5; /* Barva pozadí pro světelný režim */
  color: #000; /* Barva textu pro světelný režim */
}

/* Specifické styly pro v-app-bar */
.v-app-bar.dark-mode {
  background-color: #333; /* Barva pozadí pro tmavý režim */
  color: #fff; /* Barva textu pro tmavý režim */
}

.v-app-bar.light-mode {
  background-color: #fff; /* Barva pozadí pro světelný režim */
  color: #000; /* Barva textu pro světelný režim */
}
</style>
