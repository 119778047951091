<template>
  <v-app :class="{ 'dark-mode': isDarkMode }">
    <v-container>
      <v-container class="d-flex text-center align-center" style="margin-top: 50px;">
        <v-img style="width:200px; height:200px;" src="/storage/logo_black.png"></v-img>
      </v-container>

      <v-card class="card-container">
        <v-card-actions class="align-end justify-end">
          <NotificationConfigure />
        </v-card-actions>

        <v-alert 
          density="compact"
          text="Pokud se Vám nebudou zobrazovat data, odhlašte se a znova přihlašte."
          type="info"
        ></v-alert>

        <v-card-title class="headline">ÚKOLNÍČEK</v-card-title>

        <v-card-text>
          <div v-if="userData" class="user-details">
            <p class="subtitle-1">Uživatel: {{ userData.name }}</p>
            <p class="subtitle-1">Email: {{ userData.email }}</p>
            <TaskModal :isDarkMode="isDarkMode" :user-data="userData" @task-saved="fetchData" />
          </div>

          <!-- Checkbox pro filtrování veřejných úkolů -->
           <v-checkbox
    v-model="showPublicTasks"
    label="Zobrazit veřejné úkoly"
    @change="fetchData"
  ></v-checkbox>


          <UserDetailDialog
            v-model="modalVisible"
            :task="selectedTask"
            :isDarkMode="isDarkMode"
          />

         <v-data-table
    :headers="headers"
    :items="list"
    :loading="loading"
    class="elevation-1"
    :search="search"
    :items-per-page="-1"
  >
    <!-- Template pro zobrazení úkolů -->
    <template v-slot:item="props">
      <tr @click="openModal(props.item)">
        <td>{{ props.item.title }}</td>
        <td>{{ props.item.description_truncate }}</td>
        <td>{{ props.item.answer }}</td>
        <td>
          <div v-for="(assignedUser, index) in props.item.assigned_users" :key="index">
            <span v-if="index > 0">,</span>{{ assignedUser.user.name }}
          </div>
        </td>
        <td>{{ props.item.created_at_formatted }}</td>
        <td>
          <span v-if="props.item.status === 1">Zadáno</span>
          <span v-else-if="props.item.status === 2">Přijato</span>
          <span v-else-if="props.item.status === 3">Zpracovává se</span>
          <span style="color:green;" v-else-if="props.item.status === 4">Vyřešeno</span>
          <span v-else>Neznámý status</span>
        </td>
        <td>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-chip variant="flat" color="green" v-if="props.item.public_task" v-on="on">
                <span slot="badge">Veřejný úkol</span>
              </v-chip>
            </template>
            <span>Tento úkol</span>
          </v-tooltip>
        </td>
      </tr>
    </template>
  </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
  import axios from "axios";
import Navbar from "../../components/Navbar.vue";
import TaskModal from "../../components/TaskModal.vue";
import NotificationConfigure from "../../components/NotificationConfigure.vue";
import UserDetailDialog from "./components/UserDetailDialog.vue";

export default {
  
  props: {
    isDarkMode: {
      type: Boolean,
      default: false
    },
  },
  name: "Home",
  components: {
    Navbar,
    TaskModal,
    NotificationConfigure,
    UserDetailDialog
  },
  data() {
    return {
      userData: null,
      list: [],
      loading: false,
      showPublicTasks: true, // Přidáme stav pro checkbox
      headers: [
        { title: "Název", key: "title" },
        { title: "Popis", key: "description_truncate" },
        { title: "Odpověď", key: "answer_truncate" },
        { title: "Řeší", key: "assigned_user_name" },
        { title: "Vytvořeno", key: "created_at_formatted" },
        { title: "Status", key: "status" },
        { title: "", key: "status" }
      ],
      modalVisible: false,
      selectedTask: null,
      isHovered: false,
      search: ''
    };
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;
        // Dynamicky nastavíme parametr public_filter podle stavu checkboxu
        const publicFilter = this.showPublicTasks ? '' : '0'; // "" = zobrazit všechny, "0" = skryté úkoly
        
        // Zahrneme parametr public_filter do API volání
        const response = await axios.get(`https://betatodo.oznamzde.cz/api/task?google_id=${this.userData.google_id}&public_filter=${publicFilter}`);
        
        this.list = response.data.tasks.map(task => ({
          id: task.id,
          title: task.title,
          description: task.description,
          created_at_formatted: task.created_at_formatted,
          answer_truncate: task.answer_truncate,
          description_truncate: task.description_truncate,
          answer: task.answer,
          assigned_user_name: task.assigned_user_name,
          status: task.status,
          assigned_users: task.assigned_users,
          attachments: task.attachments,
          public_task: task.public_task,
          token: task.token
        }));
        this.list.sort((a, b) => a.row_id - b.row_id);
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        this.loading = false;
      }
    },
    openModal(task) {
      this.selectedTask = task;
      this.modalVisible = true;
    },
    setHoverStyle(isHovered) {
      this.isHovered = isHovered;
    },
  },
  mounted() {
    const storedName = localStorage.getItem('name');
    const storedEmail = localStorage.getItem('email');
    const storedGoogleId = localStorage.getItem('google_id');
    const storedRoleId = localStorage.getItem('role_id');

    if (!storedEmail || !storedGoogleId) {
      this.$router.push('/');
      return;
    }

    this.userData = {
      name: storedName,
      email: storedEmail,
      google_id: storedGoogleId,
      role_id: storedRoleId
    };

    this.fetchData();
  }
};

</script>



<style scoped>
.dark-mode {
  background-color: #121212;
  color: #e0e0e0;
}

.dark-mode-dialog .v-card {
  background-color: #333;
  color: #e0e0e0;
}

.attachment-btn {
  margin-right: 8px;
}

.download-btn {
  margin-left: 8px;
}

.card-container {
  margin-top: 20px;
}

.dark-mode-btn {
  background-color: #333; /* Tmavé pozadí pro tlačítka */
  color: #e0e0e0; /* Světlá barva textu */
  border-color: #444; /* Barva okraje tlačítka */
}

</style>



<style scoped>
.card-container {
  max-width: 1800px;
  margin: 20px auto;
}

.dark-mode .v-card {
  background-color: #444444; /* Tmavé pozadí pro karty */
  color: #e0e0e0; /* Světlý text */
}

.dark-mode .v-btn {
  background-color: #333333; /* Tmavé pozadí pro tlačítka */
  color: #ffffff; /* Světlý text na tlačítkách */
}

.dark-mode .v-chip {
  background-color: #444444; /* Tmavé pozadí pro chipy */
  color: #ffffff; /* Světlý text na chipách */
}

.dark-mode .v-alert {
  background-color: #333333; /* Tmavé pozadí pro alerty */
  color: #e0e0e0; /* Světlý text */
}

.dark-mode .v-data-table {
  background-color: #444444; /* Tmavé pozadí pro tabulky */
  color: #e0e0e0; /* Světlý text v tabulkách */
}

.dark-mode .v-data-table thead {
  background-color: #333333; /* Tmavé pozadí pro hlavičky tabulek */
  color: #e0e0e0; /* Světlý text v hlavičkách tabulek */
}



.dark-mode .v-data-table td {
  color: #e0e0e0; /* Světlý text v buňkách tabulek */
}

.dark-mode .v-skeleton-loader {
  background-color: #333333; /* Tmavé pozadí pro skeleton loader */
}

</style>