<template>
  <v-dialog v-model="modalOpen" max-width="500px" >
    <v-card class="dialog-content" :class="{ 'dark-mode': isDarkMode }">
      <v-card-title :class="{ 'dark-mode': isDarkMode }">Vytvořit úkol</v-card-title>
      <v-card-text :class="{ 'dark-mode': isDarkMode }">
        <v-text-field v-model="task.title" label="Název" :class="{ 'dark-mode': isDarkMode }"></v-text-field>
     <span style="color:green;">(poznámka je bez limitu.)</span>
        <v-textarea v-model="task.description" label="Poznámka" :class="{ 'dark-mode': isDarkMode }"></v-textarea>
        <span :class="{ 'dark-mode': isDarkMode }">Nahrát přílohu</span>
      <v-alert text="Upload příloh je nastaven na maximálně 150mb."></v-alert>

        <q-uploader
          color="teal"
          flat
          bordered
          style="max-width: 100%;"
          @added="onFileAdded"
          v-model="task.attachments"
          multiple
          :class="{ 'dark-mode': isDarkMode }"
        />

        <!-- Přidání progress baru -->
        <div v-if="uploadProgress !== null && task.attachments.length > 0" :class="{ 'dark-mode': isDarkMode }">
          <span>Probíhá nahrávání souborů: {{ uploadProgress }}%</span>
          <v-progress-linear
            v-if="uploadProgress !== null"
            :value="uploadProgress"
            color="blue"
            indeterminate
            :class="{ 'dark-mode': isDarkMode }"
          ></v-progress-linear>
        </div>
      </v-card-text>
      <v-card-actions :class="{ 'dark-mode': isDarkMode }">
        <v-btn @click="saveTask" :loading="loading" rounded :class="{ 'dark-mode': isDarkMode }">Uložit</v-btn>
        <v-btn @click="closeModal" rounded :class="{ 'dark-mode': isDarkMode }">Zrušit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-btn @click="openModal" :class="{ 'dark-mode': isDarkMode }">Nový úkol</v-btn>

  <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout">
    {{ snackbar.text }}
  </v-snackbar>
</template>

<script>
import axios from "axios";

export default {
  name: "TaskModal",
  props: {
    isDarkMode: Boolean,
    userData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      modalOpen: false,
      loading: false,
      task: this.resetTask(),
      snackbar: {
        show: false,
        text: "",
        color: "success",
        timeout: 3000,
      },
      uploadProgress: null, // Nový atribut pro sledování stavu progrese
    };
  },
  methods: {
    resetTask() {
      return {
        user_id: null,
        title: "",
        description: "",
        attachments: [],
      };
    },
    openModal() {
      this.modalOpen = true;
    },
    async saveTask() {
      if (!this.task.title) {
        this.snackbar.text = "Poznámka úkolu je povinný";
        this.snackbar.color = "error";
        this.snackbar.show = true;
        return; // Přerušení funkce, pokud chybí Poznámka úkolu
      }

      this.task.user_id = this.userData?.google_id;
      this.loading = true;

    
      try {
        const formData = new FormData();
        formData.append("user_id", this.task.user_id);
        formData.append("title", this.task.title);
        formData.append("description", this.task.description);

        // Přidání všech příloh do formData
        for (let i = 0; i < this.task.attachments.length; i++) {
          formData.append("attachments[]", this.task.attachments[i]);
        }

        // Nastavení posluchače pro upload progress
        const config = {
          onUploadProgress: (progressEvent) => {
            this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          },
        };

        const response = await axios.post("https://betatodo.oznamzde.cz/api/tasks/create", formData, config);

        this.$emit("task-saved");
        this.closeModal();

        this.snackbar.text = "Úspěšně uloženo";
        this.snackbar.show = true;
      } catch (error) {
        this.snackbar.text = "Chyba při ukládání úkolu";
        this.snackbar.color = "error";
        this.snackbar.show = true;
      } finally {
        this.loading = false;
        this.uploadProgress = null; // Resetovat stav progrese po dokončení nahrávání
      }
    },


    closeModal() {
      this.task = this.resetTask();
      this.modalOpen = false;
    },
onFileAdded(files) {
  // Přidání všech souborů do příloh
  for (let i = 0; i < files.length; i++) {
    this.task.attachments.push(files[i]);
  }
}

  },
};
</script>

<style scoped>
/* Tmavé téma */
.dark-mode {
  background-color: #333; /* Tmavé pozadí */
  color: #fff; /* Světlý text */
}

.dark-mode .v-card {
  background-color: #444; /* Tmavé pozadí karty */
  color: #fff; /* Světlý text karty */
}

.dark-mode .v-card-title {
  color: #fff; /* Světlý text pro název karty */
}

.dark-mode .v-card-text {
  color: #fff; /* Světlý text pro obsah karty */
}

.dark-mode .v-btn {
  color: #fff; /* Světlý text pro tlačítka */
}

.dark-mode .v-btn:disabled {
  background-color: #666; /* Tmavé pozadí pro neaktivní tlačítka */
}

.dark-mode .v-progress-linear {
  background-color: #666; /* Tmavé pozadí progress baru */
  color: #fff; /* Světlý text progress baru */
}

.dark-mode .v-snackbar {
  background-color: #444; /* Tmavé pozadí snackbar */
  color: #fff; /* Světlý text snackbar */
}

/* Přizpůsobení pro q-uploader */
.dark-mode .q-uploader {
  background-color: #444; /* Tmavé pozadí uploaderu */
  color: #fff; /* Světlý text uploaderu */
}
</style>
