<template>
  <div :class="{ 'dark-mode': isDarkMode }">
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-col cols="6">
            <v-text-field
              v-model="search"
              label="Hledat"
              outlined
              :class="{ 'dark-mode': isDarkMode }"
            ></v-text-field>
          </v-col>
          <v-card class="elevation-5" rounded :class="{ 'dark-mode': isDarkMode }">
            <v-data-table
              :headers="headers"
              :items="filteredUsers"
              :loading="loading"
              :items-per-page="10"
              class="elevation-1"
              :class="{ 'dark-mode': isDarkMode }"
            >
              <template v-slot:loading>
                <v-skeleton-loader
                  type="table-row@10"
                  :class="{ 'dark-mode': isDarkMode }"
                ></v-skeleton-loader>
              </template>

              <template v-slot:item="{ item }">
                <tr>
                  <td :class="{ 'dark-mode': isDarkMode }">{{ item.name }}</td>
                  <td :class="{ 'dark-mode': isDarkMode }">{{ item.email }}</td>
                  <td :class="{ 'dark-mode': isDarkMode }">
                    <!-- Iterace přes týmy a zobrazení jejich názvů -->
                    <div v-for="(team, index) in item.teams" :key="index">
                      {{ team.team_name }}
                    </div>
                  </td>
                  <td :class="{ 'dark-mode': isDarkMode }">
                    <template v-if="item.role_id === 1">Admin</template>
                    <template v-else-if="item.role_id === 2">Zadavatel</template>
                    <template v-else-if="item.role_id === 3">Vedení</template>
                    <template v-else>Neznámá role</template>
                  </td>
                  <td :class="{ 'dark-mode': isDarkMode }">
                    <v-btn
                      v-if="storedRoleId !== '3'"
                      rounded
                      size="large"
                      variant="text"
                      @click="openEditDialog(item)"
                      :class="{ 'dark-mode': isDarkMode }"
                    >
                      <v-icon size="small" class="me-2">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="storedRoleId !== '3'"
                      rounded
                      size="large"
                      variant="text"
                      @click="deleteUser(item)"
                      :class="{ 'dark-mode': isDarkMode }"
                    >
                      <v-icon size="small" class="me-2">mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
          <EditUserDialog
            v-model="editDialog"
            :user="selectedUser"
            :roles="roles"
            :teams="allteams"
            @save="saveChanges"
            @close="closeEditDialog"
            :dialog="editDialog"
            :isDarkMode="isDarkMode"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      :class="{ 'dark-mode': isDarkMode }"
    >
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false">
          Zavřít
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import EditUserDialog from "./EditUserDialog.vue";

export default {
  props: {
    isDarkMode: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EditUserDialog,
  },
  data() {
    return {
      users: [],
      allteams: [],
      loading: false,
      editDialog: false,
      selectedUser: {},
      storedRoleId: null,
      snackbar: {
        show: false,
        message: "",
        color: "success",
        timeout: 6000,
      },
      roles: [
        { id: 1, name: "Administrátor" },
        { id: 2, name: "Zadavatel" },
        { id: 3, name: "Vedení" },
      ],
      headers: [
        { title: "Jméno", value: "name" },
        { title: "E-mail", value: "email" },
        { title: "Týmy", value: "teams" },  // Přidání nového sloupce pro týmy
        { title: "Role", value: "role_id" },
        { title: "Možnosti", key: "actions", visible: true },
      ],
      search: "",
    };
  },
  computed: {
    filteredUsers() {
      return this.users.filter(
        (user) =>
          user.name.toLowerCase().includes(this.search.toLowerCase()) ||
          user.email.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  mounted() {
    const storedEmail = localStorage.getItem("email");
    const storedGoogleId = localStorage.getItem("google_id");
    this.storedRoleId = localStorage.getItem("role_id");

    if (
      !storedEmail ||
      !storedGoogleId ||
      !this.storedRoleId ||
      (this.storedRoleId !== "1" && this.storedRoleId !== "3")
    ) {
      this.$router.push("/prihlaseni");
      return;
    }

    this.userData = {
      email: storedEmail,
      google_id: storedGoogleId,
      role_id: this.storedRoleId,
    };

    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;
        const response = await axios.get("https://betatodo.oznamzde.cz/api/users", {
          headers: {
            Authorization: "Bearer 76875765675",
          },
        });
        this.users = response.data.users;
        this.allteams = response.data.allteams;
        this.loading = false;
      } catch (error) {
        console.error("Error loading data:", error);
      }
    },
    deleteUser(user) {
      const headers = {
        Authorization: "Bearer 76875765675",
      };

      axios
        .delete(`https://betatodo.oznamzde.cz/api/deleteUser/${user.user_id}`, { headers })
        .then((response) => {
          console.log(response.data.message);
          this.showSnackbar("Uživatel úspěšně smazán", "error");
          this.fetchData();
        })
        .catch((error) => {
          console.error("Error deleting user:", error.response.data.error);
        });
    },
    openEditDialog(item) {
      this.selectedUser = { ...item };
      this.editDialog = true;
    },
    async saveChanges(updatedUser) {
      if (!this.selectedUser.user_id) {
        console.error('User ID is missing');
        return;
      }

      const headers = {
        Authorization: "Bearer 76875765675",
        "Content-Type": "application/json",
      };

      try {
        const response = await axios.put(
          `https://betatodo.oznamzde.cz/api/updateUserRole/${this.selectedUser.user_id}`,
          updatedUser,
          { headers }
        );
        console.log(response.data.message);
        this.showSnackbar("Úspěšně uloženo");
        this.fetchData();
        this.closeEditDialog();
      } catch (error) {
        console.error("Error updating user role:", error.response.data.error);
      }
    },
    showSnackbar(message, color = "success") {
      this.snackbar.message = message;
      this.snackbar.color = color;
      this.snackbar.show = true;
    },
    closeEditDialog() {
      this.editDialog = false;
      this.selectedUser = {};
    },
  },
};
</script>

<style scoped>
.v-container {
  max-width: 1200px;
  margin: 20px auto;
  margin-top: 0px;
}
.v-card {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}
.dark-mode .v-card,
.dark-mode .v-snackbar,
.dark-mode .v-data-table,
.dark-mode .v-text-field,
.dark-mode .v-skeleton-loader {
  background-color: #444;
  color: #fff;
}
.dark-mode .v-btn {
  color: #fff;
}
.dark-mode .v-icon {
  color: #fff;
}
</style>
