import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { cs } from 'vuetify/locale'; // Import češtiny

const vuetify = createVuetify({
  components,
  directives,
  locale: {
    locale: 'cs', // Nastavení výchozího jazyka na češtinu
    messages: { cs }, // Přidání češtiny do lokalizace
  },
});

export default vuetify;
