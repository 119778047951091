<template>
  <v-dialog
    v-slot:default="{ isActive }"
    max-width="800"
    :class="{ 'dark-mode-dialog': isDarkMode }"
  >
    <v-card class="dialog-card">
      <v-card-title
        class="headline"
        :class="{ 'dark-mode-title': isDarkMode }"
      >
        <v-icon left>mdi-tasks</v-icon>
        <p class="subtitle-1 mt-4">Vytvořeno: {{ task.created_at_formatted }}</p>
        <h4>Název: {{ task.title }}</h4>
        <p class="description" v-html="formattedDescription"></p>

        <!-- Assigned Users Section -->
        <div class="d-flex align-center">
          <p class="subtitle-1">Zpracovatelé:</p>
          <template v-if="task.assigned_users && task.assigned_users.length > 0">
            <v-chip
              v-for="(assignedUser, index) in task.assigned_users"
              :key="index"
              color="primary"
              class="ma-1"
            >
              {{ assignedUser.user.name }}
            </v-chip>
          </template>
          <template v-else>
            <p style="margin-left:10px;" class="body-1">Zatím žádný řešící</p>
          </template>
        </div>

        <!-- Status Section -->
        <div class="d-flex align-center">
          <p class="subtitle-1">Status:</p>
          <p style="margin-left:10px;" class="body-1">
            <span v-if="task.status === 1" style="color: #F44336;">Zadáno</span>
            <span v-else-if="task.status === 2" style="color: #FFC107;">Přijato</span>
            <span v-else-if="task.status === 3" style="color: #03A9F4;">Zpracovává se</span>
            <span v-else-if="task.status === 4" style="color: #4CAF50;">Vyřešeno</span>
            <span v-else style="color: #9E9E9E;">Neznámý stav</span>
          </p>
        </div>

        <v-divider></v-divider>

        <!-- Attachments Section -->
        <div v-if="task.attachments && task.attachments.length > 0" class="attachments-section mt-4">
          <p class="subtitle-1">Vaše pílohy:</p>
          <v-btn
            v-for="(attachment, index) in task.attachments"
            :key="index"
            @click="openAttachment(attachment.filename)"
            color="secondary"
            class="ma-1"
          >
            <v-icon left>mdi-paperclip</v-icon>
            {{ attachment.filename }}
          </v-btn>
        </div>

        <v-divider></v-divider>

        <!-- Communication Button -->
        <div style="margin-top:12px;">
          <v-btn
            :to="`/chat/${task.token}`"
            :class="{ 'dark-mode-btn': isDarkMode }"
            tag="router-link"
            variant="tonal"
          >
            <v-icon left>mdi-message-text</v-icon>
            Komunikovat se zpracovatelem
          </v-btn>
        </div>

        <!-- Answer Section -->
        <p class="description" v-if="task.answer !== null">Odpověď: {{ task.answer }}</p>
      </v-card-title>

      <v-divider></v-divider>

      <!-- Close Button -->
      <v-card-actions class="justify-end">
        <v-btn @click="isActive.value = false" color="primary">
          <v-icon left>mdi-close</v-icon>
          Zavřít
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- Messages List Component -->
    <messages-list
      v-if="showMessages"
      :value="showMessages"
      :messages="task.messages"
      @close="showMessages = false"
      :google_id="google_id"
    />
  </v-dialog>
</template>

<script>
import MessagesList from './Messages-list.vue';

export default {
  components: {
    MessagesList
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    task: {
      type: Object,
      default: () => ({})
    },
    isDarkMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showMessages: false,
      dialog: false
    };
  },
  computed: {
    localVisible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    formattedDescription() {
      if (!this.task.description) {
        return '';
      }
      const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
      return this.task.description.replace(urlPattern, '<a href="$1" target="_blank" class="link" rel="noopener noreferrer">$1</a>');
    }
  },
  methods: {
    openAttachment(filename) {
      window.open(`https://betatodo.oznamzde.cz/storage/attachments/${filename}`, '_blank');
    },
    closeDialog() {
      this.localVisible = false;
    },
    showMessagesList() {
      this.showMessages = true;
    }
  }
};
</script>


<style scoped>
.dark-mode-dialog .v-card {
  background-color: #333;
  color: #e0e0e0;
}

.dark-mode-title {
  color: #e0e0e0;
}

.attachment-btn {
  margin-right: 8px;
}

.download-btn {
  margin-left: 8px;
}

.dark-mode-btn {
  color: #e0e0e0;
}

.dialog-card {
  display: flex;
  flex-direction: column;
  height: auto;
}

.description {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  margin-bottom: 16px;
}

.description .link {
  color: #1E88E5;
  text-decoration: underline;
}

.description .link:hover {
  color: #1565C0;
}

.v-card-title {
  overflow: hidden;
}

.v-card-actions {
  margin-top: auto;
}
</style>
