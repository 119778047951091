<template>
  <q-btn @click="logout" flat rounded color="primary" label="Odhlásit se" />
</template>

<script>
export default {
  data() {
    return {
      userData: null, // Objekt pro uchování údajů o uživateli
    };
  },
  mounted() {
    // Kontrola, zda jsou data v localStorage, a nastavení do userData
    const storedEmail = localStorage.getItem('email');
    const storedGoogleId = localStorage.getItem('google_id');
    const storedRoleId = localStorage.getItem('role_id');

    if (storedEmail && storedGoogleId) {
      this.userData = {
        email: storedEmail,
        google_id: storedGoogleId,
        role_id: storedRoleId
      };
    }
  },
  methods: {
    async logout() {
      // Odstranění dat o uživateli z localStorage
      localStorage.removeItem('email');
      localStorage.removeItem('google_id');
      localStorage.removeItem('role_id');

      // Nulování userData
      this.userData = null;

      // Přesměrování na stránku /prihlaseni
      this.$router.push({ path: '/prihlaseni', query: { logoutSuccess: true } });
    },
  },
};
</script>
