// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import LoginView from '../pages/Login/LoginGoogle.vue';
import Home from '../pages/User/Home.vue';
import AdminPage from '../pages/Admin/AdminPage.vue';
import UsersAndRoles from '../pages/UserManage/UsersAndRoles.vue';
import  MessageList from '../pages/User/components/Messages-list.vue';
import  MessageListAdmin from '../pages/Admin/components/Messages-list.vue';
const routes = [
  {
    path: '/prihlaseni',
    name: 'LoginView',
    component: LoginView
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/user/todo',
    name: 'UserTodo',
    component: Home
  },
  {
    path: '/admin/todo',
    name: 'AdminTodo',
    component: AdminPage
  },
  {
    path: '/settings/users',
    name: 'UsersAndRoles',
    component: UsersAndRoles
  },

  {
    path: '/chat/:token',
    name: 'MessageList',
    component: MessageList,
    props: true // Povoluje předávání parametru jako prop
  },


  {
    path: '/chatadm/:token',
    name: 'MessageListAdmin',
    component: MessageListAdmin,
    props: true // Povoluje předávání parametru jako prop
  }

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),

  routes
});

export default router;
