<template>
  <v-container class="pa-0 fill-height" fluid>
    <v-row class="d-flex align-center justify-center fill-height">
      <v-col cols="12" sm="10" md="9" lg="8" class="d-flex justify-center">
        <v-card class="chat-card">
          <v-card-title class="chat-header">
            <span class="chat-title">Kontext: {{ taskTitle }}</span>
          </v-card-title>
          <v-card-text ref="messageContainer" class="chat-messages">
            <div v-if="loading" class="loading-wrapper">
              <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
            </div>
            <div v-else-if="messages.length">
              <div 
                v-for="message in messages" 
                :key="message.id" 
                :class="[
                  'message-wrapper', 
                  { 'message-left': message.reply === 0 || !message.reply },
                  { 'message-right': message.reply === 1 }
                ]"
              >
                <div class="message-content" :class="{ 'reply-message': message.reply === 1 }">
                  <span>{{ message.message }}</span>
                </div>
              </div>
            </div>
            <div v-else>
              <p>Napište první zprávu.</p>
            </div>
          </v-card-text>
          <v-card-actions class="chat-footer">
            <v-textarea v-model="newMessage" placeholder="Napište zprávu" hide-details dense></v-textarea>
            <v-btn icon @click="sendMessage">
              <v-icon>mdi-send</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'MessageList',
  props: {
    token: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      messages: [],
      newMessage: '',
      userId: 1, // ID aktuálního uživatele
      taskTitle: '',
      loading: false,
      googleId: '',
      reply: 0
    };
  },
  mounted() {
    this.fetchMessages();
    this.googleId = localStorage.getItem('google_id');
  },
  methods: {
    async fetchMessages() {
      if (!this.token) return;
      
      this.loading = true;
      try {
        const response = await axios.get(`https://betatodo.oznamzde.cz/api/v2/tasks/${this.token}/messages-admin`);
        this.messages = response.data.messages;
        this.taskTitle = response.data.description_or_title;
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      } catch (error) {
        console.error('Error fetching messages:', error);
      } finally {
        this.loading = false;
      }
    },
    async sendMessage() {
      if (this.newMessage.trim() === '' || !this.token) return;

      try {
        const response = await axios.post(`https://betatodo.oznamzde.cz/api/v2/tasks/${this.token}/messages-admin`, {
          message: this.newMessage,
          user_id: this.userId,
          google_id: this.googleId,
          reply: this.reply
        });

        this.messages.push(response.data.message);
        this.newMessage = '';

        this.$nextTick(() => {
          this.scrollToBottom();
        });
      } catch (error) {
        console.error('Error sending message:', error);
      }
    },
    scrollToBottom() {
      this.$refs.messageContainer.scrollTop = this.$refs.messageContainer.scrollHeight;
    }
  }
};
</script>

<style scoped>
.v-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-card {
  background-color: #f5f5f5;
  border-radius: 12px;
  max-height: 800px;
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.chat-header {
  background-color: #00796b;
  color: white;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 12px;
  display: flex;
  align-items: center;
  font-size: 1rem;
}

.chat-title {
  font-weight: 500;
}

.chat-messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 12px;
  background-color: #ffffff;
}

.chat-footer {
  background-color: #f5f5f5;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 12px;
  display: flex;
  align-items: center;
}

.message-wrapper {
  display: flex;
  margin-bottom: 10px;
}

.message-right {
    justify-content: flex-start;

}

.message-left {
  justify-content: flex-end;
}

.message-content {
  max-width: 75%;
  padding: 10px;
  border-radius: 12px;
  background-color: #e0e0e0;
  color: #000;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.reply-message {
  background-color: #00796b;
  color: white;
}

.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
