<template>
  <v-dialog v-model="internalDialogVisible">
    <v-card class="custom-card" :class="{ 'dark-mode rounded-lg': isDarkMode }">
      <v-card-title>Úprava úkolu: {{ editedTask.title }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
    
        <!-- Other form elements -->
        <div class="form-group">
          <v-combobox
            v-model="selectedUser"
            :items="assignedUsers"
            item-title="name"
            item-value="id"
            label="Zpracovatelé"
            multiple
            chips
            deletable-chips
            :disabled="storedRoleId === '3'"
          >
            <template #selection="{ item }">
              <v-chip :key="item.id" v-bind="item">{{ item.name }}</v-chip>
            </template>
          </v-combobox>
        </div>
        <div class="form-group">
          <v-select
            v-model="editedTask.status"
            :items="status"
            item-title="name"
            label="Status"
            :disabled="storedRoleId === '3'"
          ></v-select>
        </div>
        <div class="form-group">
          <v-select
            v-model="editedTask.priority"
            :items="priorities"
            item-title="name"
            label="Priorita"
          ></v-select>
        </div>
   <div class="form-group">
    
                  <p style="font-size:18px;">Poznámka: {{ editedTask.description }}</p>



        <!-- Communication Button -->
        <div style="margin-top:12px;">
          <v-btn
            :to="`/chatadm/${editedTask.token_admin}`"
            :class="{ 'dark-mode-btn': isDarkMode }"
            tag="router-link"
            variant="tonal"
          >
            <v-icon left>mdi-message-text</v-icon>
            Komunikovat se zadavatelem
          </v-btn>
        </div>


        </div>


        <div class="form-group">
          <v-textarea
            v-model="editedTask.answer"
            label="Odpověď:"
            clearable
            :disabled="storedRoleId === '3'"
          ></v-textarea>
        </div>
        <v-checkbox
          v-model="publicTask"
          :true-value="1"
          :false-value="0"
          label="Veřejný úkol"
        ></v-checkbox>

        <v-row v-if="selectedTask.attachments && selectedTask.attachments.length > 0">
          <v-col cols="12">
            <p class="caption">Přílohy:</p>
            <v-row>
              <template v-for="(attachment, index) in selectedTask.attachments" :key="index">
                <v-col cols="12">
                  <v-tooltip text="Zobrazit">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="attachment-btn"
                        text
                        :href="`https://betatodo.oznamzde.cz/storage/attachments/${attachment.filename}`"
                        target="_blank"
                        :class="{ 'dark-mode': isDarkMode }"
                      >
                        Zobrazit: {{ attachment.filename }}
                      </v-btn>
                    </template>
                  </v-tooltip>
                </v-col>
              </template>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn @click="saveChanges" v-if="storedRoleId !== '3'" :loading="isLoading">
          Uložit změny
        </v-btn>
        <v-btn @click="closeDialog">Zavřít</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="snackbar.show" :color="snackbar.color" bottom>
    {{ snackbar.text }}
  </v-snackbar>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    dialogVisible: Boolean,
    selectedTask: Object,
    attachments: Array,
    isDarkMode: Boolean,
    allAdmins: Array,
  },

  data() {
    return {

      publicTask: 0,
      status: [
        { name: 'Zadáno', value: 1 },
        { name: 'Přijato', value: 2 },
        { name: 'Zpracovává se', value: 3 },
        { name: 'Vyřešeno', value: 4 },
      ],
      priorities: [
        { name: 'Nízká', value: 1 },
        { name: 'Střední', value: 2 },
        { name: 'Vysoká', value: 3 },
      ],
      assignedUsers: [],
      editedTask: {
        id: null,
        title: '',
        description: '',
        token_admin: '',
        status: null,
        priority: null,
        answer: null,
        assigned_id: [],
      },
      selectedUser: [],
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      isLoading: false,
    };
  },
  computed: {
    internalDialogVisible: {
      get() {
        return this.dialogVisible;
      },
      set(value) {
        this.$emit('update:dialogVisible', value);
      },
    },
  },
  watch: {
    async selectedTask(newVal) {
      if (newVal) {
        this.editedTask.id = newVal.id;
        this.editedTask.title = newVal.title;
        this.editedTask.description = newVal.description;
        this.editedTask.token_admin = newVal.token_admin;
        this.editedTask.status = newVal.status;
        this.editedTask.priority = newVal.priority;
        this.editedTask.answer = newVal.answer;

        // Update assigned users list
        this.assignedUsers = this.allAdmins;

        // Update selected users based on task data
        const assignedUserIds = newVal.assigned_users.map(user => user.user.id);
        this.selectedUser = this.assignedUsers.filter(user =>
          assignedUserIds.includes(user.id)
        );

        this.publicTask = newVal.public_task;
      }
    },
  },
  methods: {
    async saveChanges() {
      try {
        const userIds = this.selectedUser.map(user => user.id);
        const response = await axios.post('https://betatodo.oznamzde.cz/api/assigned-users/updateorcreate', {
          task_id: this.editedTask.id,
          user_ids: userIds,
        });
        console.log(response.data.message);
      } catch (error) {
        console.error('Chyba při ukládání změn:', error);
      }
      try {
        this.editedTask.public_task = this.publicTask ? 1 : 0;
        await this.updateTask(this.editedTask.id, {
          title: this.editedTask.title,
          description: this.editedTask.description,
          status: this.editedTask.status,
          priority: this.editedTask.priority,
          answer: this.editedTask.answer,
          public_task: this.editedTask.public_task,
        });
      } catch (error) {
        console.error('Chyba při ukládání změn:', error);
      }
    },
    async updateTask(taskId, newData) {
      try {
        if (!newData.status || newData.priority === null) {
          console.error('Vyplňte status a prioritu.');
          return;
        }
        this.isLoading = true;
        const response = await axios.put(`https://betatodo.oznamzde.cz/api/tasks/${taskId}`, newData);
        console.log(response.data.message);
        this.showSnackbar('Uloženo', 'success');
        this.$emit('update:dialogVisible', false);
        this.$emit('updateTableData');
      } catch (error) {
        console.error('Chyba při aktualizaci úkolu:', error);
        this.showSnackbar('Chyba při ukládání', 'error');
      } finally {
        this.isLoading = false;
      }
    },
    showSnackbar(text, color) {
      this.snackbar.show = true;
      this.snackbar.text = text;
      this.snackbar.color = color;
    },
    closeDialog() {
      this.$emit('close');
      this.$emit('update:dialogVisible', false);
    },
  },
};
</script>



<style scoped>
.custom-card {
  width: 600px; /* Set the desired width */
  margin: 0 auto; /* Center the card horizontally */
}

.form-group {
  margin-bottom: 20px;
}

#status, #priority, #answer, #assigned_id {
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%; /* Full-width */
}

#status:focus, #priority:focus, #answer:focus, #assigned_id:focus {
  outline: none;
  border-color: #4CAF50; /* Change border color on focus if desired */
}

option {
  font-size: 14px;
  padding: 5px;
}

/* Styl pro tmavý režim */
.dark-mode {
  background-color: #333; /* Tmavé pozadí */
  color: #fff; /* Světlý text */
}

/* Styl pro komponenty */
.dark-mode .v-card {
  background-color: #444; /* Tmavé pozadí karty */
  color: #fff; /* Světlý text karty */
}

.dark-mode .v-card-title {
  color: #fff; /* Světlý text pro název karty */
}

.dark-mode .v-card-text {
  color: #fff; /* Světlý text pro obsah karty */
}

.dark-mode .v-btn {
  color: #fff; /* Světlý text pro tlačítka */
}

.dark-mode .v-btn:disabled {
  background-color: #666; /* Tmavé pozadí pro neaktivní tlačítka */
}

/* Styl pro snackbar */
.dark-mode .v-snackbar {
  background-color: #444; /* Tmavé pozadí snackbar */
  color: #fff; /* Světlý text snackbar */
}


</style>
