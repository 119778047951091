<template>
  <div>
       <!-- Overlay a Spinner -->
    <v-overlay :value="!isInitialized" absolute>
      <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
    </v-overlay>
    <!-- Podmínkové zobrazení Sidebaru -->
    <Sidebar v-if="!isLoginPage" :isDarkMode="isDarkMode" @toggle-dark-mode="toggleDarkMode" />
    <v-app :class="{ 'dark-mode': isDarkMode }">
      <v-main>
        <router-view :isDarkMode="isDarkMode" />
      </v-main>
    </v-app>
  </div>
</template>
<script>
import { ref, onMounted, computed, watch, nextTick } from 'vue';
import { useRoute, useRouter } from 'vue-router'; 
import Sidebar from './components/admin/Sidebar.vue';

export default {
  name: 'App',
  components: {
    Sidebar
  },
  setup() {
    const isDarkMode = ref(JSON.parse(localStorage.getItem('isDarkMode')) || false);
    const isInitialized = ref(false); // Přidejte tuto proměnnou
    const route = useRoute(); 
    const router = useRouter(); 

    // Funkce pro přepínání tmavého režimu
    const toggleDarkMode = () => {
      isDarkMode.value = !isDarkMode.value;
      localStorage.setItem('isDarkMode', JSON.stringify(isDarkMode.value));
    };

    // Získání aktuální cesty a určení, zda se jedná o přihlašovací stránku
    const isLoginPage = computed(() => route.path === '/prihlaseni');

    const checkGoogleId = async () => {
      const googleId = localStorage.getItem('google_id'); // Získání google_id z úložiště
      if (!googleId) {
        return; // Pokud google_id není dostupné, funkce se ukončí
      }

      try {
        const response = await fetch('https://betatodo.oznamzde.cz/api/v2/check-google-id', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ google_id: googleId }) // Poslání google_id jako JSON
        });

        if (!response.ok) {
          const errorText = await response.text();
          console.error('Chyba při kontrole google_id:', errorText);
          router.push('/prihlaseni');
          return;
        }

        const data = await response.json();
        if (!data.exists) {
          localStorage.removeItem('google_id');
          router.push('/prihlaseni');
        } else {
          isInitialized.value = true; // Aplikace je připravena
        }
      } catch (error) {
        console.error('Chyba při kontrole google_id:', error);
        router.push('/prihlaseni');
      }
    };

    onMounted(async () => {
      await nextTick();
      isDarkMode.value = JSON.parse(localStorage.getItem('isDarkMode')) || false;
      await checkGoogleId(); // Volání funkce pro kontrolu google_id
    });

    watch(() => route.path, (newPath) => {
      if (newPath === '/prihlaseni') {
        checkGoogleId();
      }
    });

    return {
      isDarkMode,
      toggleDarkMode,
      isLoginPage,
      isInitialized // Vrácení proměnné pro řízení overlay
    };
  }
};
</script>

<style scoped>

.dark-mode {
  background-color: #333;
  color: #fff;
}


</style>
