<template>
  <v-dialog  v-model="detailModal" max-width="700px" scrollable  :class="{ 'dark-mode ': isDarkMode }">
    <v-card v-if="task" class="task-detail-card rounded-xl">
      <v-card-title class="headline primary white--text">
        Detail úkolu

      </v-card-title>
      
      <v-card-text class="pt-4">
        <v-row dense>
          <v-col cols="12">
            <h2 class="text-h5 mb-2">{{ task.title }}</h2>
          </v-col>


             <v-col cols="12">
  <strong>Řešící:</strong>
  <div v-if="task.assigned_users.length > 0">
    <div v-for="(user, index) in task.assigned_users" :key="index" dense>
      <div>
        <div class="user-item">
          <v-avatar color="blue" size="32" class="mr-2">
            <v-icon>mdi-account</v-icon>
          </v-avatar>
          <v-list-item-title>{{ user.user.name }}</v-list-item-title>
        </div>
      </div>
    </div>
  </div>
  <p v-else class="mt-1">Žádní přiřazení uživatelé</p>
</v-col>


          
          <v-col cols="12" sm="6">
                         Status úkolu:
                        <v-chip :color="getStatusColor(task.status)" dark>
                        {{ getStatusText(task.status) }}
                        </v-chip>
                    </v-col>
    
          <v-col cols="12">
            <v-divider class="my-3"></v-divider>
          </v-col>


        <v-row dense>
            <v-col cols="12" sm="6">
                <strong>Poznámka:</strong>
                <p class="mt-1">{{ task.description }}</p>
            </v-col>

            <v-col cols="12" sm="6">
                <strong>Vytvořeno:</strong> {{ task.created_at_formatted }}
            </v-col>

            <v-col cols="12" sm="6">
                <strong>Odpověď:</strong>
                <p class="mt-1">{{ task.answer || 'Zatím bez odpovědi' }}</p>
            </v-col>
            
            <v-col cols="12" sm="6" v-if="task.resolved_date">
                <strong>Vyřešeno dne:</strong> {{ task.resolved_date }}
            </v-col>
        </v-row>
        </v-row>
      </v-card-text>
      
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeModal">Zavřít</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    task: {
      type: Object,
      default: null,
    },
        isDarkMode: Boolean,

    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      detailModal: this.show,
    };
  },
  watch: {
    show(value) {
      this.detailModal = value;
    },
    detailModal(value) {
      this.$emit('update:show', value);
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
  

       getStatusText(status) {
            const statusTexts = {
                1: 'Zadáno',
                2: 'Přijato',
                3: 'Zpracovává se',
                4: 'Vyřešeno',
            };
            return statusTexts[status] || 'Unknown';
        },
    getStatusColor(status) {
            const statusColors = {
                1: 'blue',
                2: 'green',
                3: 'orange',
                4: 'purple',
            };
            return statusColors[status] || 'grey';
        },
  },
};
</script>

<style scoped>
.task-detail-card {
  border-radius: 8px;
}
.headline {
  font-weight: 500;
}
.user-item {
  display: flex;
  align-items: center;
}


/* Styl pro komponenty */
.dark-mode .v-card {
  background-color: #444; /* Tmavé pozadí karty */
  color: #fff; /* Světlý text karty */
}

</style>



