<template>
  <div style="min-height: 100%;" :class="{ 'dark-mode': isDarkMode }">
    <!-- Main Template -->
    <v-container fluid>
      <v-row align="center" justify="center">
        <div class="filter-todo">

        <FilterComponent @filter-changed="handleFilterChange" />
        </div>
        <v-col cols="12">
          <v-card class="elevation-5 rounded" :class="{ 'dark-mode-card': isDarkMode }">
            <v-card-text>
              <TaskModal :isDarkMode="isDarkMode" :user-data="userData" @task-saved="fetchData" />
              <v-data-table
                :headers="headers"
                :items="data"
                :loading="loading"
                class="elevation-1"
                :search="search"
                :class="{ 'dark-mode-table': isDarkMode }"

                  @click:row="openEditDialog(item)"
              >
                <!-- Data table slots -->
                <template v-slot:item="{ item }">
                  <tr :class="getRowClass(item.status)" @click="openEditDialog(item)"> <!-- Přidání události kliknutí na řádek -->
                    <td>{{ item.title }}</td>
                    <td>{{ item.description_truncate }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ getStatusName(item.status) }}</td>
                    <td>{{ getPriorityName(item.priority) }}</td>
                    <td>{{ item.answer_truncate }}</td>
                    <td>
                      <div v-if="item.assigned_users.length > 0">
                        <div v-for="(user, index) in item.assigned_users" :key="index">
                          {{ user.user.name }}<span v-if="index < item.assigned_users.length - 1">, </span>
                        </div>
                      </div>
                      <div v-else>
                        Žádní přiřazení zpracovatelé
                      </div>
                    </td>
                    <td>{{ item.created_at_formatted }}</td>
                    <td>{{ item.resolved_date }}</td>
                    <td>
                      <v-chip variant="flat" :color="item.public_task === 1 ? 'green' : 'red'" dark>
                        {{ item.public_task === 1 ? 'Ano' : 'Ne' }}
                      </v-chip>
                    </td>
                    <td>
                      <v-btn rounded size="large" variant="text" @click="openEditDialog(item)">
                        <v-icon size="small" class="me-2">mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn rounded size="large" variant="text" @click="openDetailModal(item)">
                        <v-icon size="small" class="me-2">mdi-information-outline</v-icon>
                      </v-btn>
                    </td>
                    <td v-if="storedRoleId !== '3'">
                      <v-btn rounded size="large" variant="text" @click="deleteTask(item.delete)">
                        <v-icon size="small" class="me-2">mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
                <template v-slot:header>
                  <th v-if="storedRoleId !== '3'">Odstranit</th>
                </template>
              </v-data-table>
            </v-card-text>
           <EditDialog
    ref="editDialogComponent"
    :dialogVisible="dialogVisible"
    :selectedTask="selectedTask"
    :isDarkMode="isDarkMode"
    :allAdmins="allAdmins"
    @updateTableData="updateTableData"
    @close="closeEditDialog"
    @update:dialogVisible="updateDialogVisibility"
  />
          </v-card>
          <v-snackbar v-model="snackbar.show" :timeout="5000">{{ snackbar.message }}</v-snackbar>
        </v-col>
      </v-row>
    </v-container>
    <TaskDetailDialog
      :task="selectedTask"
      :show="detailModal"
      @update:show="detailModal = $event"
      @close="closeDetailModal"
      :isDarkMode="isDarkMode"
    />
  </div>
</template>
<script>
import axios from 'axios';
import EditDialog from '../../components/admin/EditDialog.vue';
import FilterComponent from '../../components/admin/FilterComponent.vue';
import Sidebar from '../../components/admin/Sidebar.vue';
import TaskModal from "../../components/TaskModal.vue";
import TaskDetailDialog from "./TaskDetailDialog.vue";

export default {
  props: {
    isDarkMode: {
      type: Boolean,
      default: false
    }
  },
  components: {
    EditDialog,
    FilterComponent,
    Sidebar,
    TaskModal,
    TaskDetailDialog,
  },
  data() {
    return {
    allAdmins: [],

      status: [
        { name: 'Zadáno', value: 1 },
        { name: 'Přijato', value: 2 },
        { name: 'Zpracovává se', value: 3 },
        { name: 'Vyřešeno', value: 4 }
      ],
      priorities: [
        { name: 'Nízká', value: 1 },
        { name: 'Střední', value: 2 },
        { name: 'Vysoká', value: 3 }
      ],
      headers: [
        { title: 'Název', key: 'title' },
        { title: 'Poznámka', key: 'description_truncate' },
        { title: 'Od', key: 'email' },
        { title: 'Status', key: 'status' },
        { title: 'Priorita', key: 'priority' },
        { title: 'Odpověď', key: 'answer_truncate' },
        { title: 'Zpracovatelé', key: 'assigned_users' },
        { title: 'Vytvořeno', key: 'created_at_formatted' },
        { title: 'Vyřešeno dne', key: 'resolved_date' },
        { title: 'Veřejný úkol', key: 'public_task' },
        { title: 'Možnosti', key: 'actions' },
        { title: 'Odstranit', key: 'delete', visible: true },
      ],
      snackbar: { show: false, message: '' },
      data: [],
      loading: false,
      dialogVisible: false,
      selectedTask: null,
      filters: {},
      storedRoleId: null,
      storedGoogleId: null,
      darkMode: false,
      detailModal: false,
      attachments: null,
      userData: null,
      search: '',
    };
  },
  mounted() {
    const storedEmail = localStorage.getItem('email');
    this.storedGoogleId = localStorage.getItem('google_id');
    this.storedRoleId = localStorage.getItem('role_id');

    if (!storedEmail || !this.storedGoogleId || !this.storedRoleId || (this.storedRoleId !== '1' && this.storedRoleId !== '3')) {
      this.$router.push('/prihlaseni');
      return;
    }

    this.userData = { email: storedEmail, google_id: this.storedGoogleId, role_id: this.storedRoleId };

    this.fetchData();
  },
  methods: {
    getStatusName(value) {
      const statusObj = this.status.find(status => status.value === value);
      return statusObj ? statusObj.name : 'Neznámý';
    },
    getPriorityName(value) {
      const priorityObj = this.priorities.find(priority => priority.value === value);
      return priorityObj ? priorityObj.name : 'Neznámý';
    },
    getRowClass(status) {
      if (status === 1) {
        return 'row-light-red'; // Světle červená
      } else if (status === 4) {
        return 'row-light-green'; // Světle zelená
      }
      return '';
    },
    async fetchData(filterData = {}) {
      this.loading = true;
      try {
        const { from, to, assigned_id, team_id } = filterData;
        const formattedAssignedId = Array.isArray(assigned_id) ? assigned_id.join(',') : assigned_id;
        const formattedTeamId = Array.isArray(team_id) ? team_id.join(',') : team_id;

        const response = await axios.get('https://betatodo.oznamzde.cz/api/tasks', {
          params: { from, to, assigned_id: formattedAssignedId, team_id: formattedTeamId, google_id: this.storedGoogleId },
        });

        const tasks = response.data.tasks || [];

        this.allAdmins = response.data.all_admins || [];  // Aktualizace seznamu adminů

        if (!tasks.length) {
          this.snackbar.show = true;
          this.snackbar.message = 'Žádné záznamy nebyly nalezeny v zadaném časovém rozmezí.';
          this.data = [];
        } else {
          this.data = tasks.map((task) => ({
            id: task.id,
            title: task.title,
            priority: task.priority,
            description: task.description,
          token_admin : task.token_admin,
            email: task.email,
            status: task.status,
            row_id: task.row_id,
            answer: task.answer,
            public_task: task.public_task,
            answer_truncate: task.answer_truncate,
            description_truncate: task.description_truncate,
            assigned_users: task.assigned_users || [],  // Uložení přiřazených uživatelů
            created_at_formatted: task.created_at_formatted,
            resolved_date: task.resolved_date,
            delete: task.id,
            attachments: task.attachments,
          }));

          this.data.sort((a, b) => a.row_id - b.row_id);
        }
      } catch (error) {
        this.snackbar.show = true;
        this.snackbar.message = error.response ? error.response.data.error : 'Chyba při získávání dat ze serveru.';
        console.error('Error fetching data from the server:', error);
      } finally {
        this.loading = false;
      }
    },
    async deleteTask(id) {
      try {
        await axios.delete(`https://betatodo.oznamzde.cz/api/task/${id}/delete`);
        this.snackbar.show = true;
        this.snackbar.message = 'Záznam byl úspěšně odstraněn.';
        await this.fetchData(this.filters);
      } catch (error) {
        this.snackbar.show = true;
        this.snackbar.message = error.response ? error.response.data.error : 'Chyba při odstraňování záznamu.';
        console.error('Error deleting task:', error);
      }
    },
    openDetailModal(task) {
      this.selectedTask = task;
      this.detailModal = true;
    },
    closeDetailModal() {
      this.selectedTask = null;
      this.detailModal = false;
    },
      
     openEditDialog(task,event) {

      this.selectedTask = task;
      this.dialogVisible = true;
      this.attachments = task.attachments;
      // Ensure that the task object includes description and title
      console.log(this.selectedTask.description, this.selectedTask.title);
    },
    closeEditDialog() {
      this.dialogVisible = false;
    },
    updateDialogVisibility(value) {
      this.dialogVisible = value;
    },
    handleFilterChange(filterData) {
      this.filters = { ...filterData };
      this.fetchData(filterData);
    },
    async updateTableData() {
      await this.fetchData(this.filters);
    },
  },
};
</script>

<style scoped>
/* Světle červená pro světlý režim */
.row-light-red {
  background-color: #ffcccc;
}

/* Světle zelená pro světlý režim */
.row-light-green {
  background-color: #ccffcc;
}

/* Světle červená pro tmavý režim */
.dark-mode .row-light-red {
  background-color: #550000; /* Tmavší červená */
}

/* Světle zelená pro tmavý režim */
.dark-mode .row-light-green {
  background-color: #003300; /* Tmavší zelená */
}

</style>

<style scoped>
.v-container {
  max-width: 2000px;

}

.filter-todo {
  margin-top: 200px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /* Horizontální centrování */
  align-items: center;    /* Vertikální centrování */
}

.v-card {
  width: 100%;
}

.dark-mode {
  background-color: #333;
  color: #fff;
}

.dark-mode-table {
  background-color: #444;
  color: #fff;
}

.dark-mode-card {
  background-color: #444;
  color: #fff;
}

.dark-mode-skeleton {
  background-color: #555;
}
</style>
<style scoped>
/* Efekt hover pro řádky tabulky */
.v-data-table tbody tr:hover {
  background-color: #f5f5f5; /* Světle šedá barva pro hover efekt */
  cursor: pointer;
}

/* Specifické třídy pro tmavý režim */
.dark-mode .v-data-table tbody tr:hover {
  background-color: #666; /* Tmavší šedá pro tmavý režim */
}
</style>
