<template>
  <v-container fluid class="fill-height">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6" lg="4">
        <v-card class="elevation-12 rounded-xl login-card">
          <v-card-text class="text-center pt-12">
            <v-avatar size="80" color="#F3E5AB" class="mb-4">
              <img style="width:100%" src="/todoic.svg">
            </v-avatar>
            <h1 class="text-h4 font-weight-light mb-2">Přihlášení do</h1>
            <h2 class="text-h3 font-weight-bold mb-6">TODO</h2>
            
            <v-btn
              :loading="isLoading"
              :disabled="isLoading"
              @click="handleSignInGoogle"
              class="login-btn"
              rounded="pill"
              size="x-large"
              block
              elevation="2"
            >
              <v-icon start icon="mdi-google" class="mr-2"></v-icon>
              {{ isLoading ? 'Přihlašuji Vás...' : 'Přihlásit se pomocí Google' }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Version info positioned at the bottom right -->
    <div class="version-info">
      Verze BETA: 1.1.6
    </div>
  </v-container>
</template>

<style scoped>
.login-card {
  background-color: rgba(255, 255, 255, 0.8);
  max-width: 500px;
  margin: 0 auto; 
  padding: 20px; 
  border-radius: 10px; 
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 
}

.login-btn {
  transition: all 0.3s ease;
}

.login-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.version-info {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  }

</style>


<script>
import axios from "axios";
import firebaseApp from "@/firebase";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

firebaseApp;

export default {
  name: "LoginGoogle",

  data() {
    return {
      user: null,
      isSignedIn: false,
      userUid: null,
      isLoading: false, // Stav pro načítání
    };
  },
  computed: {
    userEmail() {
      return localStorage.getItem('email') || '';
    },
    userRoleId() {
      return localStorage.getItem('role_id') || '';
    },
    userGoogleId() {
      return localStorage.getItem('google_id') || '';
    }
  },
  methods: {
    async handleSignInGoogle() {
      const providerGoogle = new GoogleAuthProvider();
      try {
        const result = await this.signInWithProvider(providerGoogle);
        if (result.user) {
          this.isLoading = true; // Nastavení načítání na true až po úspěšném přihlášení
          this.user = result.user;
          this.isSignedIn = true;
          this.userUid = result.user.uid;
          await this.sendUserDataToBackend(result.user);
          this.redirectUser(); // Přesměrování uživatele
        } else {
          throw new Error("Firebase user object is undefined or null");
        }
      } catch (error) {
        console.error("Error signing in or sending data:", error);
      } finally {
        this.isLoading = false; // Deaktivace načítání po přesměrování nebo chybě
      }
    },

    signInWithProvider(provider) {
      const auth = getAuth();
      return signInWithPopup(auth, provider);
    },

async sendUserDataToBackend(user) {
  try {
    const response = await axios.post("https://betatodo.oznamzde.cz/api/auth/google", {
      google_id: user.uid,
      email: user.email,
      name: user.displayName,
    });
    const { email, role_id, google_id, name } = response.data;

    localStorage.setItem('email', email);
    localStorage.setItem('role_id', role_id);
    localStorage.setItem('google_id', google_id);
    localStorage.setItem('name', name); // Uložíme jméno do localStorage

  } catch (error) {
    console.error("Error calling backend:", error);
  }
},

    redirectUser() {
      const roleId = localStorage.getItem('role_id');
      if (roleId === '1') {
        this.$router.push('/admin/todo');
      } else if (roleId === '2') {
        this.$router.push('/user/todo');
      } else {
        console.error("Unknown role_id:", roleId);
      }
    }
  },
};
</script>
