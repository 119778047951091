<template>
   

        <v-tooltip text="Nastavení notifikací">
        <template v-slot:activator="{ props }">
            <v-btn @click="openDialog" color="primary" dark  v-bind="props">
                Notifikace na G-mail
            <v-icon >mdi-cog</v-icon>

            </v-btn>
        </template>
    </v-tooltip>


    <v-dialog v-model="dialog" max-width="500px">
        <v-card>
  <v-alert
    color="info"
    text="Po povolení notifikací na G-mail Vám přijde upozornění po změně statusu Vašeho zadaného úkolu."
  ></v-alert>

            <v-card-title class="headline">Nastavení notifikací</v-card-title>
            <v-card-text>
<div style="display: flex; align-items: center;">
    <v-switch v-model="notifications"></v-switch>
    <span style="color:green;" v-if="notifications">Notifikace jsou povoleny</span>
    <span v-else>Notifikace nejsou povoleny</span>
</div>



            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" @click="saveSettings">Uložit</v-btn>

                <v-btn color="blue darken-1" text @click="closeDialog">Zavřít</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

   
    <v-snackbar v-model="showSnackbar" bottom :timeout="3000">
        <span>Nastavení notifikací bylo úspěšně uloženo.</span>
    </v-snackbar>

</template>
<script>
import axios from 'axios';

export default {
    data() {
        return {
            dialog: false,
            notifications: false,
            storedGoogleId: '',
            showSnackbar: false
        };
    },
    mounted() {
        this.storedGoogleId = localStorage.getItem('google_id');
        this.loadSettings();
    },
    methods: {
        async loadSettings() {
            try {
                const response = await axios.get(`https://betatodo.oznamzde.cz/api/notification-settings/${this.storedGoogleId}`);
                const { notifications } = response.data;
                console.log('Hodnota notifikací z API:', notifications);
                this.notifications = notifications === 1 ? true : false;
            } catch (error) {
                console.error('Chyba při načítání nastavení notifikací:', error);
            }
        },
        async saveSettings() {
            try {
                const notificationValue = this.notifications ? 1 : 0;
                const response = await axios.put(`https://betatodo.oznamzde.cz/api/notification-settings/${this.storedGoogleId}`, { notifications: notificationValue });
                this.dialog = false;
                this.showSnackbar = true; // Show snackbar after successful save
                console.log('Hodnota notifikací byla úspěšně uložena:', response.data.notifications);
                setTimeout(() => {
                    this.showSnackbar = false; // Hide snackbar after timeout
                }, 3000);
            } catch (error) {
                console.error('Chyba při ukládání nastavení notifikací:', error);
            }
        },
        openDialog() {
            this.dialog = true;
        },
        closeDialog() {
            this.dialog = false;
        }
    }
};
</script><style>
.v-snackbar.bottom {
    bottom: 0;
}
</style>