<template>
  <v-dialog v-bind:value="dialog" v-on:input="updateDialog" max-width="600">
    <v-card :class="{ 'dark-mode': isDarkMode }">
      <v-card-title class="headline">Upravit nastavení uživatele</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <label for="roleSelect">Změnit roli:</label>
              <v-select
                v-model="selectRole"
                :items="roles"
                item-value="id"
                item-title="name"
                label="Vyberte roli"
                :class="{ 'dark-mode': isDarkMode }"
                dense
                outlined
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <label for="teams">Týmy:</label>
              <v-select
                v-model="selectedTeams"
                :items="teams"
                item-value="team_id"
                item-title="team_name"
                label="Vyberte týmy"
                multiple
                :class="{ 'dark-mode': isDarkMode }"
                dense
                outlined
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="saveChanges" color="primary" :class="{ 'dark-mode': isDarkMode }">Uložit změny</v-btn>
        <v-btn @click="closeDialog" color="error" :class="{ 'dark-mode': isDarkMode }">Zavřít</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      default: () => ({}),
    },
    roles: {
      type: Array,
      required: true,
    },
    teams: {
      type: Array,
      default: () => [],
    },
    isDarkMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectRole: this.user.role_id || null,
      selectedTeams: (this.user.teams || []).map(team => team.team_id),
      editedName: this.user.name || '',
    };
  },
  methods: {
    saveChanges() {
      this.$emit("save", {
        role_id: this.selectRole,
        name: this.editedName,
        teams: this.selectedTeams,
      });
    },
    closeDialog() {
      this.$emit("close");
    },
  },
  watch: {
    user(newUser) {
      this.selectRole = newUser.role_id || null;
      this.selectedTeams = (newUser.teams || []).map(team => team.team_id);
      this.editedName = newUser.name || '';
    },
  },
};
</script>

<style scoped>
.dark-mode {
  background-color: #333;
  color: #fff;
}
.dark-mode .v-select .v-input__control {
  background-color: #444;
  color: #fff;
}
</style>
