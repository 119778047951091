<template>
  <v-app-bar app>
    <!-- Your v-app-bar content goes here -->
    <div style="margin:20px;">
      <Logout />
    </div>
  </v-app-bar>
</template>

<script>
import Logout from "./Auth/Logout.vue";

export default {
  components: {
    Logout,
  },
  data() {
    return {
      menu: false,
      menuX: 0,
      menuY: 0,
    };
  },
  methods: {
    toggleDrawer() {
      this.$emit('toggle-drawer');
    },
    openMenu(event) {
      this.menuX = event.clientX;
      this.menuY = event.clientY;
      this.menu = true;
    },
    closeMenu(option) {
      this.menu = false;
      console.log(`Selected option: ${option}`);
    },
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
